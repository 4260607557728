import Cookies from 'js-cookie'

const accessTokenCookie = 'tm5-HelloAsso'
const refreshTokenCookie = 'rm5-HelloAsso'

/**
 * @typedef {Object} ReadCookiesResult
 * @property {(string|null)} refreshToken - The refresh token read from cookies.
 * @property {(string|null)} accessToken - The access token read from cookies.
 */

/**
 * Get the access and refresh tokens from the cookies.
 *
 * @returns {ReadCookiesResult}
 */
export function getTokens() {
  const accessToken = Cookies.get(accessTokenCookie)
  const refreshToken = Cookies.get(refreshTokenCookie)
  return {
    accessToken,
    refreshToken
  }
}

/**
 * Delete authentication cookies.
 */
export function deleteCookies() {
  Cookies.remove(refreshTokenCookie)
  Cookies.remove(accessTokenCookie)
}
