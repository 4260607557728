import { createStore } from 'vuex'
import { defineNuxtPlugin } from '#imports'
import password from '@/stores/password'
import register from '@/stores/register'
import regularization from '@/stores/regularization'
import sso from '@/stores/sso'
import { createHttps } from '@/services/httpService'
import useConfigEnv from '@/composables/useConfigEnv'

export default defineNuxtPlugin((nuxtApp) => {
  const configEnv = useConfigEnv()
  const { httpApi, httpOAuth, httpAuthProxy } = createHttps(configEnv)
  const modulePassword = password({ httpAuthProxy })
  const moduleRegister = register({ httpAuthProxy, httpApi })
  const moduleRegularization = regularization({ httpAuthProxy })
  const moduleSSO = sso({ httpOAuth, httpAuthProxy, httpApi })

  const store = createStore({
    modules: {
      password: modulePassword,
      register: moduleRegister,
      regularization: moduleRegularization,
      sso: moduleSSO
    }
  })
  nuxtApp.vueApp.use(store)
})
