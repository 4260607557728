import { defineNuxtPlugin } from '#app'

import useRecordBrowserRum from '@/composables/useRecordBrowserRum'
import type { BrowserRumError } from '@/types/browserRum.interface'

export default defineNuxtPlugin((nuxtApp) => {
  const { recordError } = useRecordBrowserRum()
  nuxtApp.hook('vue:error', (error) => {
    recordError(error as BrowserRumError)
  })
})
