import { useLogger } from '@ha/components-v3'
import type { BrowserRumError } from '@/types/browserRum.interface'
export default () => {
  const { datadogRum } = useLogger()

  const setFingerprint = (
    error: BrowserRumError,
    fingerprint: string,
    prefix?: string
  ) => {
    error.dd_fingerprint = fingerprint
    error.message = `${prefix} - ${error.message}`
  }

  const recordError = (error: BrowserRumError) => {
    datadogRum.addError(error)
  }

  return {
    setFingerprint,
    recordError
  }
}
