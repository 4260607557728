import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apiOAuth_6qM7mIl5om from "/app/src/domains/core/plugins/apiOAuth.ts";
import apiV5_l2Ku9AZ1n9 from "/app/src/domains/core/plugins/apiV5.ts";
import apiInternal_5f2m8e5YML from "/app/src/domains/core/plugins/apiInternal.ts";
import error_handler_eQzajpVw3N from "/app/src/plugins/error-handler.ts";
import haPlugin_ThrBiOJWcR from "/app/src/plugins/haPlugin.js";
import gtm_Umtjvg1cZ3 from "/app/src/plugins/gtm.js";
import stores_YLLKqlv59i from "/app/src/plugins/stores.ts";
import tracking_pages_mOBeKQIfJw from "/app/src/plugins/tracking-pages.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  apiOAuth_6qM7mIl5om,
  apiV5_l2Ku9AZ1n9,
  apiInternal_5f2m8e5YML,
  error_handler_eQzajpVw3N,
  haPlugin_ThrBiOJWcR,
  gtm_Umtjvg1cZ3,
  stores_YLLKqlv59i,
  tracking_pages_mOBeKQIfJw
]