export default {
  403: {
    details:
      'Nous avons dû recevoir une alerte grâce à vous et nous sommes en train de résoudre le problème !',
    message:
      "Vous n'avez pas les droits nécessaires pour accéder à cette page.",
    title: 'Toutes nos excuses !'
  },
  404: {
    details:
      "Vérifier que cette page n'a pas été supprimée ou que vous avez tapé l'URL correctement.",
    message: "Cette page n'existe pas (ou plus).",
    title: 'Toutes nos excuses !'
  },
  500: {
    message: 'Notre serveur a renvoyé une erreur.',
    title: 'Toutes nos excuses !',
    details:
      'Nous avons dû recevoir une alerte grâce à vous et nous sommes en train de résoudre le problème !'
  },
  back: 'Retour',
  backToHome: "Revenir à l'accueil",
  redirection_1: 'Vous pouvez nous contacter',
  redirection_2: 'ici',
  redirection_3: 'au sujet de ce problème.',
  statusCode: "Code d'erreur : {0}"
}
