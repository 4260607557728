import { defineNuxtPlugin, useRouter } from '#imports'
import { useTracking } from '@ha/components-v3'

// Source: https://docs.google.com/spreadsheets/d/194FHx6xdPvaIBIVwukljO2nw_mx-8bIGkRgoGq-mfKI/edit#gid=1948717279
// Columns: D E F G
// No context provided since 'contributeurs' as well as 'admins' may use the pages
interface RouteTrackingData {
  context: string
  rubrik: string
  category: string
  title: string
}
interface RouteData {
  [key: string]: RouteTrackingData
}

const routeData: RouteData = {
  'premiere-connexion': {
    context: '',
    rubrik: 'Auth',
    category: 'Connexion',
    title: 'Première connexion'
  },
  connexion: {
    context: '',
    rubrik: 'Auth',
    category: 'Connexion',
    title: 'Connexion'
  },
  authorize: {
    context: '',
    rubrik: 'Auth',
    category: 'Connexion',
    title: 'Mire de connexion'
  },
  'mot-de-passe-oubli': {
    context: '',
    rubrik: 'Auth',
    category: 'Password reset',
    title: 'Mot de passe oublié'
  },
  'mot-de-passe-nouveau-token': {
    context: '',
    rubrik: 'Auth',
    category: 'Password reset',
    title: 'Nouveau mot de passe'
  },
  inscription: {
    context: '',
    rubrik: 'Auth',
    category: 'Inscription',
    title: 'Inscription'
  },
  'mot-de-passe-expired': {
    context: '',
    rubrik: 'Auth',
    category: 'Password expired',
    title: 'Mot de passe expiré'
  },
  bienvenue: {
    context: '',
    rubrik: 'Auth',
    category: 'Inscription',
    title: 'Page bienvenue'
  },
  suggestion: {
    context: '',
    rubrik: 'Auth',
    category: 'Inscription',
    title: 'Page carrefour'
  },
  'inscription-utilisateur': {
    context: '',
    rubrik: 'Auth',
    category: 'Inscription',
    title: 'Account creation - user'
  },
  'inscription-association': {
    context: '',
    rubrik: 'Auth',
    category: 'Inscription',
    title: 'Account creation - association'
  }
}

export default defineNuxtPlugin(() => {
  const { tracking } = useTracking()
  const router = useRouter()
  router.afterEach(({ name }) => {
    const routeName = name?.toString() || ''
    const routeDataKey = routeName.split('___')[0]
    if (routeData[routeDataKey]) tracking.page(routeData[routeDataKey])
  })
})
