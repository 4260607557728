export default {
  title: `Créez le compte de votre association {highlight}`,
  highlight: 'gratuitement',
  subtitle: 'Faisons connaissance',
  next: 'Inscrire mon association',
  firstName: 'Prénom',
  lastName: 'Nom',
  birthDate: 'Date de naissance',
  birthDateFormat: 'Au format JJ/MM/AAAA',
  birthDateCondition: 'Vous devez être majeur pour récolter de l’argent.',
  email: 'Adresse email',
  emailConnected: "Ce n'est pas vous ?",
  emailLogout: 'Déconnectez-vous',
  password: 'Mot de passe',
  passwordRules: {
    label: 'Utiliser au moins :',
    length: '10 caractères',
    number: '1 chiffre',
    special: '1 caractère spécial',
    uppercase: '1 majuscule',
    lowercase: '1 minuscule'
  },
  acceptConsent: 'Mon organisme accepte les {link}.',
  acceptConsentLink: 'conditions générales d’utilisation',
  acceptConsentError:
    "Vous devez accepter les conditions générales d'utilisation.",
  acceptOrganization:
    'Je confirme agir en tant que représentant d’un organisme et créer le compte d’une association ou d’un club domicilié en France.',
  acceptOrganizationError:
    'Vous devez confirmer la représentation d’un organisme.',
  checkEmailErrorTitle: 'Erreur lors de la création de l’utilisateur',
  checkEmailErrorBody:
    'Impossible de créer votre compte, l’e-mail est incorrect ou déjà utilisé.',
  updateUserErrorTitle: 'Erreur lors de la mise à jour de l’utilisateur'
}
