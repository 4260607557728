import { type MutationTree, type ActionTree } from 'vuex'
import { type AxiosInstance } from 'axios'

class State {
  antiForgeryToken: string = ''
}
const state = () => new State()

const mutations = <MutationTree<State>>{
  SET_ANTI_FORGERY_TOKEN(state: State, token: string) {
    state.antiForgeryToken = token
  }
}

const getActions = ({
  httpAuthProxy
}: {
  httpAuthProxy: AxiosInstance
}): ActionTree<State, any> => ({
  fetchAntiForgeryToken({ commit }) {
    return httpAuthProxy.get('/auth/antiforgerytoken').then((response) => {
      const token = response
      commit('SET_ANTI_FORGERY_TOKEN', token)
      return token
    })
  },
  /**
   * Get associated email with the password to reinit
   * @param email
   */
  passwordReset(_, { email, antiForgeryToken }) {
    return httpAuthProxy.post(
      '/auth/reset-password',
      { email },
      {
        headers: {
          RequestVerificationToken: antiForgeryToken
        }
      }
    )
  },
  /**
   * Get user by sent token
   * @param token
   */

  getUserByToken(_, { token, antiForgeryToken }) {
    return httpAuthProxy
      .get(`/auth/set-password/${token}`, {
        headers: {
          RequestVerificationToken: antiForgeryToken
        }
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  /**
   * Set new password with token
   * @param token
   */
  setPasswordByToken(_, { token, antiForgeryToken, password }) {
    return httpAuthProxy.post(
      `/auth/set-password`,
      { oneTimeToken: token, password },
      {
        headers: {
          RequestVerificationToken: antiForgeryToken
        }
      }
    )
  }
})

export default ({ httpAuthProxy }: { httpAuthProxy: AxiosInstance }) => ({
  state,
  mutations,
  actions: getActions({ httpAuthProxy }),
  namespaced: true
})
