import type {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios'
import axios from 'axios'
import { getTokens } from '@/cookies'

const interceptorError = (error: AxiosError): Promise<AxiosError> => {
  console.error(error)
  return Promise.reject(error)
}
const interceptorResponse = (response: AxiosResponse): AxiosResponse =>
  response.data

//? TODO : Rename Auth proxy which isn't a proxy
export const createAuthProxy = ({
  NUXT_ENV_BASE_API_AUTH
}: {
  NUXT_ENV_BASE_API_AUTH: string
}): AxiosInstance => {
  const httpAuthProxy = axios.create({
    baseURL: NUXT_ENV_BASE_API_AUTH,
    withCredentials: true
  })

  httpAuthProxy.interceptors.response.use(interceptorResponse, interceptorError)
  return httpAuthProxy
}

const addBearerToken = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const { accessToken } = getTokens()
  if (accessToken) config.headers.authorization = `Bearer ${accessToken}`

  return config
}

export const createAuthApi = ({
  NUXT_ENV_BASE_API_AUTH
}: {
  NUXT_ENV_BASE_API_AUTH: string
}): AxiosInstance => {
  const httpApi = axios.create({
    withCredentials: true,
    baseURL: NUXT_ENV_BASE_API_AUTH
  })

  httpApi.interceptors.request.use(addBearerToken, interceptorError)
  httpApi.interceptors.response.use(interceptorResponse, interceptorError)

  return httpApi
}

/**
 * For OAuth API.
 */
export const createOAuthApi = ({
  NUXT_ENV_AUTH_URL_AUTH
}: {
  NUXT_ENV_AUTH_URL_AUTH: string
}): AxiosInstance => {
  const httpAuthentication = axios.create({
    baseURL: NUXT_ENV_AUTH_URL_AUTH,
    withCredentials: true
  })

  httpAuthentication.interceptors.response.use(
    interceptorResponse,
    interceptorError
  )
  return httpAuthentication
}

const createHttps = (configEnv: {
  NUXT_ENV_BASE_API_AUTH: string
  NUXT_ENV_AUTH_URL_AUTH: string
}) => {
  const httpApi = createAuthApi(configEnv)
  const httpOAuth = createOAuthApi(configEnv)
  const httpAuthProxy = createAuthProxy(configEnv)
  return { httpApi, httpOAuth, httpAuthProxy }
}

export { createHttps }
