<script setup>
import { computed } from 'vue'
import useConfigEnv from '~/composables/useConfigEnv.js'
import { HaButton } from '@ha/components-v3'

definePageMeta({
  layout: 'light'
})

const props = defineProps({
  error: {
    type: Object,
    default: () => {}
  }
})

const { NUXT_ENV_BASE_URL } = useConfigEnv()

/* Used to print error in rum */
console.error(props.error)
const statusCodeText = computed(() =>
  [403, 404, 500].includes(props.error.statusCode)
    ? props.error.statusCode
    : 500
)
</script>

<template>
  <div class="error-page">
    <div class="error-page__wrapper">
      <h1 class="error-page__title">
        {{ $t(`error.${statusCodeText}.title`) }}
      </h1>
      <h2 class="error-page__subtitle">
        {{ $t(`error.${statusCodeText}.message`) }}
      </h2>
      <p class="error-page__code">
        {{ $t('error.statusCode', [error.statusCode]) }}
      </p>
      <p>
        {{ $t(`error.${statusCodeText}.details`) }}
      </p>
      <p>
        {{ $t('error.redirection_1') }}
        <a
          id="Forms_error-page_Contact"
          :href="`${NUXT_ENV_BASE_URL}/contact`"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t('error.redirection_2') }}
        </a>
        {{ $t('error.redirection_3') }}
      </p>
      <HaButton
        id="Forms_error-page_BackToHome"
        size="large"
        :href="NUXT_ENV_BASE_URL"
      >
        {{ $t('error.backToHome') }}
      </HaButton>
    </div>
  </div>
</template>

<style lang="scss">
.error-page {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: calc(100vh);
  background-color: var(--ha-color-white);

  p {
    margin-bottom: $ha-spacing-medium;
    line-height: $ha-line-height-large;

    &:last-of-type {
      margin-bottom: $ha-spacing-big;
    }
  }

  &__wrapper {
    position: relative;
    padding: $ha-spacing-large;

    @include mediaQuery(600) {
      padding: $ha-spacing-giant;
    }

    &::before {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      background: url('https://cdn.helloasso.com/images/pdf_front/illustration-abyss.svg')
        no-repeat center left;
      background-size: cover;
      content: '';
    }
  }

  &__title {
    margin-bottom: $ha-spacing-large;
    font-size: $ha-font-size-giant * 1.5; // 48px
    line-height: $ha-line-height-small;
  }

  &__subtitle {
    margin-bottom: $ha-spacing-big;
    color: var(--ha-color-text-light);
  }

  &__code {
    color: var(--ha-color-text-lightest);
    font-weight: $ha-font-weight-semibold;
  }
}
</style>
