import error from './error'
import layout from './layout'

export default {
  error,
  layout,
  views: {
    login: {
      title: 'Connexion'
    },
    authorize: {
      windowTitle: 'Se connecter avec HelloAsso',
      title: 'Se connecter avec HelloAsso',
      errors: {
        hasNoOrganization:
          'Veuillez vous connecter avec un compte administrateur ou bien créer votre compte association.'
      }
    },
    password: {
      passwordNew: {
        windowTitle: 'Nouveau mot de passe',
        backToHome: 'Retour au site',
        email: {
          label: 'Adresse email'
        },
        message:
          'Indiquez le mot de passe que vous souhaitez utiliser pour accéder à votre compte utilisateur.',
        password: {
          label: 'Choisissez un mot de passe'
        },
        title: 'Nouveau mot de passe',
        toMyAccount: 'Accéder à mon compte'
      },
      firstConnection: {
        windowTitle: 'Première connexion'
      },
      passwordReset: {
        windowTitle: 'Mot de passe oublié'
      }
    },
    unavailablePage: {
      title: 'Cette page de régularisation n’est plus disponible',
      subTitle:
        'Vérifiez que le paiement n’a pas déjà été régularisé en contactant directement l’organisme. Sinon, consultez notre ',
      helpLink: "Centre d'aide.",
      tagLine: 'Plateforme de paiement 100% sécurisée',
      content: `
Le modèle HelloAsso garantit aux associations d'être reversées de la totalité des paiements comme le vôtre. Nous n'appliquons aucun frais, aucune commission, notre unique source de revenus ne provient pas de la publicité mais de la générosité de gens comme vous qui trouvent notre service utile. Votre soutien est indispensable et permet à notre équipe d'aider encore plus d'associations gratuitement.
Merci à celles et ceux qui contribuent à faire grandir un modèle solidaire du web, et merci à ceux que ce message pourrait faire passer à l'action.
`
    },
    register: {
      banner: {
        message: 'Vous avez déjà un compte sur HelloAsso ?',
        connect: 'Connexion'
      },
      windowTitle: 'Étape {0} sur {1} - Inscription',
      // clientFetchError:
      //   'Impossible de recupérer les informations liées au client',
      chargement: 'Chargement...'
    }
  },
  components: {
    logo: {
      alt: 'logo'
    },
    accessibilityButton: {
      text: 'Accessibilité'
    }
  },
  welcome: {
    title: 'Bienvenue sur HelloAsso',
    subtitle: 'La solution des associations offerte par les citoyens',
    offer: 'Des outils {offer}, comment est-ce possible ?',
    percent: '100% offerts',
    cv: {
      title: 'Grâce aux contributions volontaires',
      description:
        'Plus concrètement ? Lors d’un paiement sur HelloAsso, chacun peut contribuer à financer notre mission. Ces contributions volontaires sont {bold}',
      revenu: 'notre seule source de revenu.'
    },
    hello: {
      title: 'Le mouvement HelloAsso',
      description:
        'Depuis 15 ans, {bold} ont pu lancer plus d’un million d’activités sur HelloAsso, pour soutenir des causes, faire vibrer la vie locale ou partager des passions. ',
      asso: '+350 000 associations'
    },
    thanks:
      'Merci pour votre engagement, pour tout ce que vous donnez à la société et {bold}',
    thanksWelcome: 'bienvenue dans le mouvement !',
    team: 'L’équipe {color}',
    ha: 'HelloAsso',
    button: 'Découvrir HelloAsso',
    confidence: 'Merci pour votre confiance !'
  },
  onboarding: {
    whatDoYouWantToCreate: {
      title: 'sur HelloAsso ?',
      highlight: 'Que souhaitez-vous créer'
    },
    suggestionForYourOrganization:
      'Voici nos suggestions pour {organizationName}. Découvrez plus d’options sur votre compte plus tard.',
    iPreferToBrowse: {
      text: 'Je préfère {link}',
      link: 'explorer par moi-même'
    },
    carousel: {
      Event: {
        body: 'Aperçu d’une {highlight}',
        highlight: 'billetterie'
      },
      Shop: {
        body: 'Aperçu d’une {highlight}',
        highlight: 'boutique'
      },
      Membership: {
        body: 'Aperçu d’un {highlight}',
        highlight: 'formulaire d’adhésion'
      },
      Crowd: {
        body: 'Aperçu d’une campagne de {highlight}',
        highlight: 'crowdfunding'
      },
      Donation: {
        body: 'Aperçu d’un {highlight}',
        highlight: 'formulaire de don'
      }
    },
    cards: {
      Event: {
        title: 'Une billetterie',
        body: 'Vendez des billets pour vos évènements : matchs, tournois, cours...'
      },
      Shop: {
        title: 'Une boutique',
        body: 'Vendez des articles ou organisez une tombola'
      },
      Membership: {
        title: 'Un formulaire d’adhésion',
        body: 'Collectez des cotisations et gérez vos adhérents'
      },
      Crowd: {
        title: 'Une collecte de fonds',
        body: 'Lancez une campagne de financement participatif (crowdfunding)'
      },
      Donation: {
        title: 'Un formulaire de don',
        body: 'Lancez votre collecte de dons pour financer vos activités'
      }
    }
  },
  containers: {
    login: {
      title: 'Connexion',
      register: 'Vous n’avez pas encore de compte ?',
      registerLink: 'Inscrivez votre organisme.',
      email: 'Adresse email',
      password: 'Mot de passe',
      submit: 'Accéder à mon compte',
      resetPassword: 'Mot de passe oublié ?',
      firstConnection: 'Première connexion ?',
      back: 'Retour au site',
      errors: {
        global: 'Oups, votre email ou votre mot de passe est incorrect.',
        api: 'Erreur lors de la connexion'
      }
    },
    password: {
      passwordExpired: {
        title: 'Mot de passe expiré',
        message:
          "Un e-mail de réinitialisation de mot de passe vous a été envoyé à l'adresse e-mail renseignée.",
        help: `Vous ne le trouvez pas ? Pensez à vérifier vos spams ou l'onglet "Promotions" de votre boite mail si vous en avez un. Parfois, un e-mail peut s'y glisser !`,
        helpNothingReceived:
          "Si vous n'avez rien reçu dans les cinq prochaines minutes, ",
        helpNothingReceivedLink: 'cliquez ici',
        helpNothingReceived2: ' pour réinitialiser votre mot de passe.',
        helpCenter:
          "Si vous voulez en savoir plus sur la sécurisation de l'accès à votre compte HelloAsso, consultez ",
        helpCenterLink: "cet article de notre centre d'aide"
      },
      passwordReset: {
        passwordResetEmail: {
          backToHome: 'Retour au site',
          email: {
            label: 'Adresse email'
          },
          message_1: 'Pas de panique !',
          message_2:
            'Entrez votre adresse email et nous vous enverrons un lien afin d’en créer un nouveau.',
          reinitPassword: 'Réinitialiser mon mot de passe',
          title: 'Mot de passe oublié'
        },
        passwordResetConfirmation: {
          // and: 'et de',
          backToSignIn: 'Retour à la connexion',
          contactEmail: 'contactez nous à cette adresse',
          first_connection:
            "Un e-mail pour initialiser votre mot de passe vous a été envoyé à l'adresse e-mail renseignée.",
          message_1:
            "Un e-mail de réinitialisation de mot de passe vous a été envoyé à l'adresse e-mail renseignée.",
          message_2: `Vous ne le trouvez pas ? Pensez à vérifier vos spams ou l'onglet "Promotions" de votre boite mail si vous en avez un. Parfois, un e-mail peut s'y glisser !`,
          message_3:
            "Vous n'avez rien reçu ? Il est possible qu'il n'existe pas de compte HelloAsso lié à cette adresse e-mail. Si vous avez un compte HelloAsso dont vous connaissez l'e-mail de connexion mais que vous n'avez pas reçu le mot de passe,",
          title: 'Parfait !'
        },
        firstConnection: {
          backToHome: 'Retour au site',
          email: {
            label: 'Adresse email'
          },
          message:
            'Entrez votre adresse email et nous vous enverrons un lien pour définir votre mot de passe.',
          reinitPassword: 'Initialiser mon mot de passe',
          title: 'Première connexion'
        }
      },
      validation: {
        length: '10 car. min',
        number: 'Un chiffre',
        uppercase: 'Une majuscule',
        lowercase: 'Une minuscule',
        specialChar: 'Un caractère spécial',
        error: {
          required: 'Ce champ est obligatoire.',
          rules:
            'Doit comporter au moins une minuscule, une majuscule, 10 caractères, un chiffre et un caractère spécial.'
        }
      }
    },
    missingParams: {
      title: 'Connexion impossible avec HelloAsso',
      description:
        "Les paramètres suivants sont manquants dans l'URL de connexion :"
    },
    authorize: {
      title: 'Bonjour, {0}',
      changeUser: "Changer d'utilisateur",
      orgLoading: 'Chargement des associations...',
      authorizePartner:
        'Vous allez autoriser {0} à effectuer les actions suivantes sur votre compte HelloAsso :',
      proceedWithOrg: "Continuer avec l'association :",
      chooseOrg: 'Choisir une association',
      accountLogin: 'Connexion à votre compte',
      cancel: 'Annuler',
      linkAccounts: 'Lier les comptes',
      youNeedToBeAnAdmin:
        "Vous devez être un administrateur d'association pour vous connecter avec la mire d'authentification."
    },
    loginForm: {
      title: 'Formulaire de connexion',
      userLoading: 'Chargement du profil utilisateur...',
      email: 'Email',
      password: 'Mot de passe',
      forgottenPassword: 'Mot de passe oublié',
      login: 'Connexion',
      noAccount: "Vous n'avez pas encore de compte ?",
      signup: 'Créer mon compte HelloAsso',
      errors: {
        global: 'Oups, votre email ou votre mot de passe est incorrect.',
        missingEmail: 'Vous devez remplir votre email.',
        missingPassword: 'Vous devez remplir votre mot de passe.',
        wrongEmailFormat:
          'Vous devez entrer un email valide (email@exemple.com).'
      }
    },
    register: {
      registerFirstStep: {
        logout: "Ce n'est pas vous ?",
        logoutButton: 'Déconnectez-vous',
        title: 'Inscrivez votre organisme gratuitement',
        individual_1: 'Vous souhaitez plutôt',
        individual_2: ' soutenir ',
        individual_3: 'un projet ou une association ?',
        individualLink: "C'est par ici",
        name: 'Nom de votre association',
        legalStructure: 'Structure juridique de votre association',
        legalStructurePlaceholder: 'Choisir la structure juridique',
        orgType: 'Votre champ d’activité',
        orgTypePlaceholder: 'Choisir une activité',
        email: 'Quel est votre email ?',
        password: 'Choisissez un mot de passe',
        isAssociation: {
          label:
            'Je confirme agir en tant que représentant d’un organisme et créer le compte d’une association ou d’un club domicilié en France. *',
          error: 'Vous devez confirmer la représentation d’un organisme.'
        },
        conditions: {
          label: 'Mon organisme accepte les',
          labelLink: "conditions générales d'utilisation.",
          error: "Vous devez accepter les conditions générales d'utilisation."
        },
        requiredInformation: '*renseignement obligatoire',
        navigation: {
          back: 'Retour',
          next: 'Inscrire mon organisme'
        },
        errors: {
          existingEmail:
            'Impossible de créer votre compte, l’e-mail est incorrect ou déjà utilisé.',
          registerOrganismErrorTitle:
            "Erreur lors de la création de l'association.",
          errorTitle: "Erreur lors de la validation du nom de l'association",
          errorFetchLegalStructureOptions:
            'Erreur lors du chargement des structures juridiques',
          takenName: 'Une association avec le même nom existe déjà',
          only_letters_digits_dash_apostrophe:
            'Le nom de votre organisme ne peut contenir que des lettres, des chiffres, des tirets ou des apostrophes.'
        }
      },
      registerSecondStep: {
        logout: "Ce n'est pas vous ?",
        logoutButton: 'Déconnectez-vous',
        title: 'Faisons un peu plus connaissance !',
        federationName: {
          labelCheckbox: 'Mon organisme est affilié à une fédération.',
          labelInput: 'Quel est le nom de la fédération ?',
          placeholderInput: 'Choisir la fédération'
        },
        address: {
          label: 'Quelle est l’adresse de votre siège social ?',
          switchToManual: 'Je veux saisir mon adresse manuellement',
          switchToAuto: "Je veux que l'on me propose une adresse",
          street: 'Numéro et nom de rue',
          zipCode: 'Code postal',
          city: 'Ville',
          tooltip: 'Commencez à taper votre adresse et sélectionnez une option.'
        },
        user: {
          firstName: {
            label: 'Quel est votre prénom ?'
          },
          lastName: {
            label: 'Quel est votre nom ?'
          },
          helpText: 'Chez nous, il y a Paul, Amélie, Ludivine, Aurélien... :)'
        },
        birthDate: {
          label: 'Quelle est votre date de naissance ?',
          subTitle: 'Au format (JJ/MM/AAAA)',
          helpText: "Vous devez être majeur pour récolter de l'argent en ligne"
        },
        phone: {
          label: 'Quel est le n° de téléphone de l’organisme ?',
          helpText:
            'En cas de besoin, votre n° de téléphone permet à notre équipe de vous accompagner'
        },
        annualBudget: {
          defaultValue: 'Choisir le budget annuel',
          label: 'Quel est le budget annuel de votre association ?'
        },
        navigation: {
          back: 'Retour',
          next: 'Accéder à mon espace'
        },
        errors: {
          budget:
            'Vous devez sélectionner une fourchette dans la liste déroulante. Cette information vous apportera un accompagnement personnalisé.',
          addressError:
            'Vous devez sélectionner une adresse depuis la liste déroulante ou entrer manuellement votre adresse en cliquant sur "{0}".',
          addressNoGeoLoc: {
            title: "L'adresse saisie n'est pas reconnue.",
            body: 'Veuillez réessayer avec une adresse proche ou un autre code postal.'
          },
          errorFetchFederationOptions:
            'Erreur lors du chargement des fédérations',
          accountAlreadyExisting: 'Toutes nos excuses !',
          registerOrganismErrorTitle:
            "Erreur lors de la création de l'association."
        }
      }
    }
  },
  errors: {
    default: {
      title: 'Oups !',
      body: 'Une erreur est survenue :('
    },
    login: {
      api: {
        title: 'Erreur lors de la connexion'
      },
      global: {
        title: 'Oups, votre email ou votre mot de passe est incorrect.'
      }
    },
    password: {
      usedToken: {
        title: 'Oups !',
        body: "Vous avez été redirigé, car votre lien de réinitialisation n'est plus valide."
      }
    },
    networkErrorBody: 'Vérifiez votre connexion Internet et réessayez.',
    statusCodeErrorBody: `Une erreur est survenue (code d'erreur : {0}). Réessayez plus tard ou contactez le support.`,
    unknownErrorBody:
      'Une erreur inconnue est survenue. Veuillez réessayer plus tard ou contactez le support.'
  }
}
