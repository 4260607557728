export default {
  legalStructures: {
    errorFetch: 'Erreur lors du chargement des structures juridiques',
    errorFetchBody:
      'Une erreur est survenue, réessayez plus tard ou contactez le support.'
  },
  federations: {
    errorFetch: 'Erreur lors du chargement des fédérations',
    errorFetchBody:
      'Une erreur est survenue, réessayez plus tard ou contactez le support.'
  },
  title: { body: '{highlight} sur HelloAsso! 👋', highlight: 'Bienvenue' },
  subtitle: 'Parlez-nous de votre association',
  goBack: 'Retour',
  next: 'Continuer',
  fields: {
    address: {
      label: 'Quelle est l’adresse de votre siège social ?',
      info: 'Nous avons besoin de votre adresse afin de vérifier que votre organisme se situe en France.',
      switchToManual: 'Je veux saisir mon adresse manuellement',
      switchToAuto: "Je veux que l'on me propose une adresse",
      street: 'Numéro et nom de rue',
      zipCode: 'Code postal',
      city: 'Ville',
      tooltip: 'Commencez à taper votre adresse et sélectionnez une option.',
      autocompleteError:
        'Vous devez sélectionner une adresse depuis la liste déroulante ou entrer manuellement votre adresse en cliquant sur "{0}".',
      manualError: {
        title: "L'adresse saisie n'est pas reconnue.",
        body: 'Veuillez réessayer avec une adresse proche ou un autre code postal.'
      }
    },
    name: {
      label: 'Nom de votre association',
      nameTakenError: 'Une association avec le même nom existe déjà',
      info: 'Il est impératif de renseigner un nom, acronyme ou un sigle déclaré en préfecture pour pouvoir collecter sur HelloAsso. ',
      tooltip: {
        body: "Diminutif ? Nom d'usage ? Section ou antenne d'une association Mère ? En cas de doute, {link}",
        link: 'consultez notre guide dédié.'
      }
    },
    budget: {
      label: 'Budget annuel',
      placeholder: 'Choisir le budget',
      error:
        'Vous devez sélectionner une fourchette dans la liste déroulante. Cette information vous apportera un accompagnement personnalisé.'
    },
    phone: {
      label: 'Numéro de téléphone',
      helpText:
        'En cas de besoin, votre numéro de téléphone permet à notre équipe de vous contacter'
    },
    federationName: {
      label: 'Quel est le nom de la fédération ?',
      placeholder: 'Choisir la fédération',
      checkbox:
        'Mon association est affiliée / en lien avec une fédération ou un réseau national'
    },
    status: {
      label: 'Structure juridique',
      placeholder: 'Choisir la structure juridique'
    },
    type: {
      label: 'Champ d’activité principal',
      placeholder: 'Choisir une activité'
    },
    errors: {
      errorFetchFederationOptions: 'Erreur lors du chargement des fédérations',
      registerOrganismErrorTitle: "Erreur lors de la création de l'association."
    }
  },
  notifications: {
    errors: {
      associationNameTaken: {
        title: "Erreur lors de la création de l'association",
        body: 'Une association avec le même nom existe déjà'
      },
      errorOccurred: {
        title: "Erreur lors de la création de l'association",
        body: ''
      }
    }
  }
}
