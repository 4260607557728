import { useRuntimeConfig } from '#imports'

export default () => {
  const config = useRuntimeConfig()

  const {
    NUXT_ENV_BASE_URL,
    NUXT_ENV_BASE_API_AUTH,
    NUXT_ENV_AUTH_URL_AUTH,
    NUXT_APP_HA_ENV,
    NUXT_ENV_COOKIE_DOMAIN,
    FEATURE_INSCRIPTION_WELCOME_PAGE,
    FEATURE_INSCRIPTION_SUGGESTION_PAGE,
    SEGMENT_KEY,
    SEGMENT_ENABLED,
    DD_SITE,
    DD_SERVICE,
    DD_ENV,
    DD_RUM_APP_ID,
    DD_RUM_CLIENT_TOKEN,
    DD_RUM_SESSION_SAMPLE_RATE,
    DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
    DD_NUXT_ENV_TIMESTAMP,
    NUXT_ENV_BO_URL_AUTH
  } = config.public

  return {
    NUXT_ENV_BASE_URL,
    NUXT_ENV_BASE_API_AUTH,
    NUXT_ENV_AUTH_URL_AUTH,
    NUXT_APP_HA_ENV,
    NUXT_ENV_COOKIE_DOMAIN,
    FEATURE_INSCRIPTION_WELCOME_PAGE,
    FEATURE_INSCRIPTION_SUGGESTION_PAGE,
    SEGMENT_KEY,
    SEGMENT_ENABLED,
    DD_SITE,
    DD_SERVICE,
    DD_ENV,
    DD_RUM_APP_ID,
    DD_RUM_CLIENT_TOKEN,
    DD_RUM_SESSION_SAMPLE_RATE,
    DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
    DD_NUXT_ENV_TIMESTAMP,
    NUXT_ENV_BO_URL_AUTH
  }
}
