import { ref } from 'vue'
import { defineNuxtPlugin } from '#imports'
import useConfigEnv from '../composables/useConfigEnv'
import updateRequestHeaders from '../helpers/updateRequestHeaders'

export default defineNuxtPlugin(() => {
  const antiForgeryToken = ref<string>('')
  const configEnv = useConfigEnv()

  const apiInternal = $fetch.create({
    baseURL: configEnv.NUXT_ENV_BASE_API_AUTH,
    async onRequest({ options }) {
      if (!antiForgeryToken.value) await fetchAntiForgeryToken()
      const headers = (options.headers ||= {})
      options.credentials = 'include'
      updateRequestHeaders(
        headers,
        'RequestVerificationToken',
        antiForgeryToken.value
      )
    },
    async onResponseError({ response }) {
      console.error(response)
    }
  })

  const fetchAntiForgeryToken = async () => {
    const token = await $fetch(
      `${configEnv.NUXT_ENV_BASE_API_AUTH}/auth/antiforgerytoken`,
      {
        credentials: 'include'
      }
    )
    antiForgeryToken.value = token as string
  }

  return {
    provide: {
      apiInternal,
      fetchAntiForgeryToken
    }
  }
})
