import { default as authorizepYduAJEVEaMeta } from "/app/src/pages/authorize.vue?macro=true";
import { default as bienvenueznt5snNdPOMeta } from "/app/src/domains/registration/pages/bienvenue.vue?macro=true";
import { default as connexionz9vQKGLodiMeta } from "/app/src/pages/connexion.vue?macro=true";
import { default as associationEz4x0V1MCCMeta } from "/app/src/domains/registration/pages/inscription/association.vue?macro=true";
import { default as utilisateurRdBs9azSXJMeta } from "/app/src/domains/registration/pages/inscription/utilisateur.vue?macro=true";
import { default as inscriptionhtAbOAY7wxMeta } from "/app/src/pages/inscription.vue?macro=true";
import { default as expiredrh9mvJs2hXMeta } from "/app/src/pages/mot-de-passe/expired.vue?macro=true";
import { default as _91token_93fqqsJ0dGPSMeta } from "/app/src/pages/mot-de-passe/nouveau/[token].vue?macro=true";
import { default as oubliIwmTm74zy0Meta } from "/app/src/pages/mot-de-passe/oubli.vue?macro=true";
import { default as page_45indisponiblezS7fXqBOSKMeta } from "/app/src/pages/paiement/page-indisponible.vue?macro=true";
import { default as _oneTimeTokenZ1ps7aB91PMeta } from "/app/src/pages/paiement/regularisation/_oneTimeToken.vue?macro=true";
import { default as _91oneTimeToken_93UZe0DhnpqsMeta } from "/app/src/pages/paiement/regularisation/[oneTimeToken].vue?macro=true";
import { default as premiere_45connexionpjsXUIMAiTMeta } from "/app/src/pages/premiere-connexion.vue?macro=true";
import { default as suggestioneJl8Qvsi38Meta } from "/app/src/pages/suggestion.vue?macro=true";
export default [
  {
    name: "authorize",
    path: "/authorize",
    meta: authorizepYduAJEVEaMeta || {},
    component: () => import("/app/src/pages/authorize.vue").then(m => m.default || m)
  },
  {
    name: "bienvenue",
    path: "/bienvenue",
    meta: bienvenueznt5snNdPOMeta || {},
    component: () => import("/app/src/domains/registration/pages/bienvenue.vue").then(m => m.default || m)
  },
  {
    name: "connexion",
    path: "/connexion",
    component: () => import("/app/src/pages/connexion.vue").then(m => m.default || m)
  },
  {
    name: "inscription",
    path: "/inscription",
    meta: inscriptionhtAbOAY7wxMeta || {},
    component: () => import("/app/src/pages/inscription.vue").then(m => m.default || m),
    children: [
  {
    name: "inscription-association",
    path: "association",
    component: () => import("/app/src/domains/registration/pages/inscription/association.vue").then(m => m.default || m)
  },
  {
    name: "inscription-utilisateur",
    path: "utilisateur",
    component: () => import("/app/src/domains/registration/pages/inscription/utilisateur.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "mot-de-passe-expired",
    path: "/mot-de-passe/expired",
    component: () => import("/app/src/pages/mot-de-passe/expired.vue").then(m => m.default || m)
  },
  {
    name: "mot-de-passe-nouveau-token",
    path: "/mot-de-passe/nouveau/:token()",
    component: () => import("/app/src/pages/mot-de-passe/nouveau/[token].vue").then(m => m.default || m)
  },
  {
    name: "mot-de-passe-oubli",
    path: "/mot-de-passe/oubli",
    component: () => import("/app/src/pages/mot-de-passe/oubli.vue").then(m => m.default || m)
  },
  {
    name: "paiement-page-indisponible",
    path: "/paiement/page-indisponible",
    meta: page_45indisponiblezS7fXqBOSKMeta || {},
    component: () => import("/app/src/pages/paiement/page-indisponible.vue").then(m => m.default || m)
  },
  {
    name: "paiement-regularisation-_oneTimeToken",
    path: "/paiement/regularisation/_oneTimeToken",
    component: () => import("/app/src/pages/paiement/regularisation/_oneTimeToken.vue").then(m => m.default || m)
  },
  {
    name: "paiement-regularisation-oneTimeToken",
    path: "/paiement/regularisation/:oneTimeToken()",
    meta: _91oneTimeToken_93UZe0DhnpqsMeta || {},
    component: () => import("/app/src/pages/paiement/regularisation/[oneTimeToken].vue").then(m => m.default || m)
  },
  {
    name: "premiere-connexion",
    path: "/premiere-connexion",
    component: () => import("/app/src/pages/premiere-connexion.vue").then(m => m.default || m)
  },
  {
    name: "suggestion",
    path: "/suggestion",
    meta: suggestioneJl8Qvsi38Meta || {},
    component: () => import("/app/src/pages/suggestion.vue").then(m => m.default || m)
  }
]